export const environment = {
    production: false,
    baseHref: '/',
    redirectUri: 'https://photo-capture.qas.ag2goservices.amgreetings.com/',
    apiScopeUrl: 'api://70222fd4-dcd5-48e4-8394-8816159e24bc/access_as_user',
    clientId: '478cdcea-e625-490e-b257-99706ca5015d',
    postLogoutRedirectUri: 'https://photo-capture.qas.ag2goservices.amgreetings.com/',
    webApi: 'https://photo-capture-api.qas.ag2goservices.amgreetings.com/api/',
    userRolesAuthorization: false,
    tablePaginationSizes: [10, 25, 50, 75, 100, 150, 200],
    pageableAllItems: 99999,
    noLoadingSpinnerHeader: 'loading-spinner-disabled',
    httpRequestChunkSize: 100,
    emojiRegEx: '^[A-Za-z0-9\\s+!@#$%^&*()-–_+=`~\\\]\[{}|\';:/.,?><]*$',
    cloudFrontRequests: '.cloudfront.net/',
    imagesBucket: 'ag2go-photocapture-qas',
    resizedImagesBucket: 'ag2go-photocapture-resized-qas'
};
